<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: 'TvContainer'
    }
</script>

<style lang="scss">
    .full-screen {
        height: 100vh;
    }
    .tv {
        border: 10px solid #dfe0e3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        font-weight: bold;
    }
</style>